import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Multi Instrumentalist",
          "Artist",
          "Engineer",
          "Producer",
          "Violin, Guitar, Bass, Drums",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
